<template>
  <div>
    <Chart :cdata="cdata" />
  </div>
</template>

<script>
/* eslint-disable */
import Chart from './chart.vue'
export default {
  data () {
    return {
      cdata: {
        category: [
          // "晋西南餐馆",
          // "小憩餐馆",
          // "晋风味",
          // "秀江南",
          // "塞北坝上大骨头",
          // "秀色可餐烘焙",
        ],
        lineData: [
          // 18092,
          // 20728,
          // 24045,
          // 28348,
          // 32808,
          // 36097,
        
        ],
        barData: [
          // 4600,
          // 5000,
          // 5500,
          // 6500,
          // 7500,
          // 8500,
         
        ],
        rateData: [
          //   18092,
          // 20728,
          // 24045,
          // 28348,
          // 32808,
          // 36097,
        ]
      }
    };
  },
  components: {
    Chart,
  },
  mounted() {
    this.setData();
    
    this.getuse();
  },
  methods: {
    // 根据自己的业务情况修改
    setData () {
      // for (let i = 0; i < this.cdata.barData.length -1; i++) {
      //   let rate = this.cdata.barData[i] / this.cdata.lineData[i];
      //   this.cdata.rateData.push(rate.toFixed(2));
        
      // }
    },
     // 根据自己的业务情况修改
    getuse(){
      this.$Http.get("wx/bigscreen/getMerchantsAdds").then(res=>{

        if(res.data.data.data != ''){
          for(let i=0;i<res.data.data.length;i++){
            this.cdata.category.push(res.data.data[i].name+res.data.data[i].sn);
            this.cdata.barData.push(res.data.data[i].yestoday)
          this.cdata.rateData.push(res.data.data[i].today)
          }         
        }
      }).catch(error=>{})
    }
  }
};
/* eslint-enable */
</script>

<style lang="scss" scoped>
</style>