<template>
  <div id="center">
    
    <div class="up">
          <div class="d-flex" style='width:100%;'>
            <span style="color:#5cd9e8;margin:7% -3% 0 5%;">
          <icon name="users"></icon>
        </span>
          <span class="fs-xl text mx-2" style='font-size:18px; color:#00fff6;margin-top:0.3rem;margin-left:5%;'>用户统计</span>
          <dv-decoration-6 style="width:1.25rem;height:.25rem; position:relative;top:.27rem;" />
        </div>
      <div class="bg-color-black item" v-for="item in titleItem" :key="item.title" >
        <p class="ml-3 colorBlue fw-b" style='text-align: center; display:block;margin-top:-.1rem;margin-left:-.05rem;'>{{item.title}}</p>
        <div>
          <dv-digital-flop :config="item.number" style="width:1.25rem;height:.625rem;margin:0 auto;" />
        </div>
      </div>
    </div>
    <div class="down">
      <div class="percent">
        <div class="item bg-color-black">
          <span style='text-aling:center;'>今日用户在线率</span>
          <CenterChart style='margin:0 auto;padding-left:10%;' :id="rate[0].id" :tips="rate[0].tips" :colorObj="rate[0].colorData" />
        </div>
        <div class="item bg-color-black">
          <span>今日用户离线率</span>
          <CenterChart style='margin:0 auto;padding-left:10%;' :id="rate[1].id" :tips="rate[1].tips" :colorObj="rate[1].colorData" />
        </div>
        <div class="water">
          <dv-active-ring-chart :config="configdata" style="width:100%;height:100%;" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
import CenterChart from "@/components/echart/center/centerChartRate";

export default {
  data () {
    return {
      time:'',
      titleItem: [
        {
          title: "在线用户数量",
          number: {
            number: [120],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "离线用户数量",
          number: {
            number: [18],
            toFixed: 1,
            content: "{nt}"
          }
        }
      ],
       configdata:{
           radius: '60%',
  showOriginValue:true,
  lineWidth: 10,
  color:["red",'#00fff6','rgb(30,30,30)'],
   digitalFlopStyle: {
    fontSize: 10
  },
           data: [
    // {
    //   name: '在线平均时长',
    //   value:5,
    // },
    {
      name: '离线时长',
      value: 12
    },
    {
      name: '总人数',
      value: 3
    }
  ],
       }, 
     

  
  

      // 通过率和达标率的组件复用数据
      rate: [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
    };
  },
  components: {
    CenterChart
    // centerChart1,
    // centerChart2
  },

  mounted () {
    this.getusers()
  this.time =  setInterval(() => {
        this.getusers();
    }, 10000);
  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  methods: {
    getusers(){
      this.$Http.get('wx/bigscreen/getUserTotal')
      .then(res=>{
        let titleItem = [
        {
          title: "在线用户数量",
          number: {
            number: [120],
            toFixed: 1,
            content: "{nt}"
          }
        },
        {
          title: "用户总数量",
          number: {
            number: [18],
            toFixed: 1,
            content: "{nt}"
          }
        }
      ]
      titleItem[0].number.number = [res.data.data.useronline];
       titleItem[1].number.number = [res.data.data.userToal];
       this.titleItem = titleItem;
    let configdata={
           radius: '60%',
           showOriginValue:true,
           lineWidth: 10,
           color:["red",'#00fff6','rgb(30,30,30)'],
           digitalFlopStyle: {
             fontSize: 10
           },
           data: [
    // {
    //   name: '在线人数',
    //   value:1,
    // },
    {
      name: '离线数',
      value: 3
    },
    // {
    //   name: '总人数',
    //   value: 3
    // }
  ],
       }
      //  configdata.data[0].value = parseInt(res.data.data.useronline);
       configdata.data[0].value =parseInt(res.data.data.leveuser) ;
      //  configdata.data[2].value = res.data.data.userToal;
       this.configdata = configdata;
             // 通过率和达标率的组件复用数据
    let  rate = [
        {
          id: "centerRate1",
          tips: 60,
          colorData: {
            textStyle: "#3fc0fb",
            series: {
              color: ["#00bcd44a", "transparent"],
              dataColor: {
                normal: "#03a9f4",
                shadowColor: "#97e2f5"
              }
            }
          }
        },
        {
          id: "centerRate2",
          tips: 40,
          colorData: {
            textStyle: "#67e0e3",
            series: {
              color: ["#faf3a378", "transparent"],
              dataColor: {
                normal: "#ff9800",
                shadowColor: "#fcebad"
              }
            }
          }
        }
      ]
      rate[0].tips = parseInt(res.data.data.online);
      rate[1].tips = parseInt(res.data.data.leveline);
      this.rate = rate;
      }).catch(error=>{

      })
    }
  },
 
};
 /* eslint-enable */
</script>

<style lang="scss" scoped>
#center {
  display: flex;
  flex-direction: column;
  .up {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .item {
      border-radius: 0.0625rem;
      padding-top: 0.2rem;
      margin-top: 0.1rem;
      width: 43%;
      height: 0.875rem;
      
    }
  }
  .down {
    padding: 0.07rem 0.05rem;
    padding-bottom: 0;
    width: 95%;
    margin: 0 auto;
    display: flex;
    height: 3.5rem;
    justify-content: space-between;
    .bg-color-black {
      border-radius: 0.0625rem;
      
    }
    .ranking {
      padding: 0.125rem;
      width: 59%;
    }
    .percent {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 50%;
        height: 1.5rem;
        span {
          margin-top: 0.0875rem;
          display: flex;
          justify-content: center;
        }
      }
      .water {
        width: 100%;
        height:1.8rem;
        // border: 8px outset #00fff6 ;
      }
    }
  }
}
</style>