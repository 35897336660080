<template>
  <div id="bottomRight">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <div class="d-flex">
          <span class="fs-xl text mx-2" style='font-size:18px; color:#00fff6;'>实时加注</span>
        </div>
      </div>
      <div>
        <!-- <BottomRightChart/> -->
        <!-- <el-table :data="ranking"  >
          <el-table-column prop="address" label="加注地点">
          </el-table-column>
          <el-table-column prop="dirverCar" label="车牌号">
          </el-table-column>
          <el-table-column prop="driverName" label="司机">
          </el-table-column>
          <el-table-column prop="driverPic" label="加注状态">
            <template slot-scope="scope">
              <div>
                {{scope.row.id?'自动':'自动'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column  label="加注图标">
            <template slot-scope="scope">
              <div>
                <img :src="scope.row.id?img1:img2" alt="">
              </div>
            </template>
          </el-table-column>
          
        </el-table> -->
        <div style="width:100%;">
          <ol class='ols'>
            <li>加注地点</li>
            <li>车牌号</li>
            <li>司机</li>
            <li>加注状态</li>
            <li>加注图标</li>
          </ol>
          <ol v-show='ranking' class='ols' v-for="(item,index) in ranking" :key='index'>
            <li>{{item.address}}</li>
            <li>{{item.dirverCar}}</li>
            <li>{{item.driverName}}</li>
            <li>{{item.id?'自动':'自动'}}</li>
            <li><img :src="item.id?img1:img2" alt=""></li> 
          </ol>
          <p v-show='msg' style='width:100%;text-align: center;margin-top:10%;'>{{msg}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import BottomRightChart from "@/components/echart/bottom/bottomRightChart";
export default {
  data() {
    return {
     ranking:[],
     img1:require('../assets/add.png'),
     img2:require('../assets/加油完成.png'),
     msg:''
    };
  },
  components: {
    BottomRightChart
  },
  mounted() {
    this.getadd();
    this.time = setInterval(() => {
       this.getadd();
    }, 10000);
    
  },
  //   beforeDestroy () {
  //   window.clearInterval(this.time);
  //   this.time = null;
  // },
  methods: {
    getadd(){
    
      this.ranking = [];
      this.$Http.get("wx/bigscreen/getMerchantsAddsnew").then(res=>{
        if(res.data.data != null){
        for(let i = 0;i<=res.data.data.length;i++){
             this.ranking.push(res.data.data[i].order) 
             this.msg = '';        
          }
          // this.use = res.data.data;
        
        }else{
          this.msg = res.data.errmsg;
          this.ranking = [];
        }
      }).catch(error=>{})
    }
  },
};
/* eslint-enable */
</script>
<style>
    .el-table__row,td,.el-table td, .el-table th.is-leaf {
     background: rgba(0, 0, 0, 0);
    border:none;
  }
</style>
<style scoped>
  .ols{
    display: flex;
    flex-wrap:nowrap;
     justify-content:space-around;
     overflow: hidden;
     margin-top: 5px;
     height: 30px;
     padding-top: 2px;
  }
  li{
    text-align: center;
    font-size: 16px;
    color: aliceblue;
    width: 20%;
  }
</style>
<style lang="scss">
#bottomRight {
  padding: 0.2rem 0.2rem 0;
  height: 6.5rem;
  min-width: 3.75rem;
  border-radius: 0.0625rem;
  .bg-color-black {
    height: 6.1875rem;
    border-radius: 0.125rem;
  }
  .text {
    color: #c3cbde;
  } //下滑线动态
  .decoration2 {
    position: absolute;
    right: 0.125rem;
  }
  .chart-box {
    margin-top: 0.2rem;
    width: 2.125rem;
    height: 2.125rem;
    .active-ring-name {
      padding-top: 0.125rem;
    }
  }
}
</style>
