<template>
  <div class="baidumap" id="allmap"></div>
</template>
<script>
/* eslint-disable */
export default {
  // props:['cars'],
  name: "TestBaiDu",
  data() {
    return {
      center: { lng: 112.524458, lat: 37.862653 },
      zoom: 13,
      markerArr: [],
      addArray: '',
      cars: '',
      map: ''
    };
  },
  watch: {
    cars(newval, oldval) {
      this.markerArr = newval;
      this.getadders()
      this.initcar();
      // for (let i = 0; i < newval.length; i++) {
      //   if (newval[i].jzGnss) {
      //     for (let i = 0; i < newval.length; i++) {
      //       if (newval[i].jzGnss) {
      //         this.markerArr.push(newval[i].jzGnss);
      //         this.initcar();
      //       }
      //     }
      //   }
      // }

    },
    deep: true
  },
  beforeDestroy() {
    clearInterval(this.time)
  },
  created() {
    this.getcar();
  },
  mounted() {
    this.time = setInterval(() => {
      this.getcar();
    }, 10000);
    this.baiduMap();
  },
  methods: {
    getcar() {

      // let request = {
      //   "Action": "GetVehicleGpsInfo",
      //   "UserId": "sxwyny",
      //   "Pwd": "D5D9E6BF8E8AEE5B91EA69D1D2498B5B",
      //   "Vehicles": [{
      //     "PlateNum": "测A000022",
      //     "ColorCode": "1"
      //   }
      //   ],
      //   "NeedAddress": "1"
      // }
      // this.cars = [
      //   {
      //     "PlateNum": "测A000022",
      //     "ColorCode": "1",
      //     "GpsTime": "2020/12/12 7:53:57",
      //     "Mileage": "0.00", "Speed": "0.0",
      //     "Longitude": "3740.5585N",
      //     "Latitude": "11241.2271E",
      //     "Address": "山西省太原市小店区晋阳街 格云楼西北50米",
      //     "Direction": "2",
      //     "Altitude": "0",
      //     "IsGpsValid": "1",
      //     "AlarmInfo": "终端主电源掉电;",
      //     "StateInfo": "ACC关;定位;运营;电路断开;"
      //   },
      //   {
      //     "PlateNum": "测A000022",
      //     "ColorCode": "1",
      //     "GpsTime": "2020/12/12 7:53:57",
      //     "Mileage": "0.00", "Speed": "0.0",
      //     "Longitude": "112.562104",
      //     "Latitude": "37.798568",
      //     "Address": "山西省太原市小店区晋阳街 格云楼西北50米",
      //     "Direction": "2", "Altitude": "0", "IsGpsValid": "1",
      //     "AlarmInfo": "终端主电源掉电;",
      //     "StateInfo": "ACC关;定位;运营;电路断开;"
      //   }]
      this.$Http.get('wx/bigscreen/getCars')

        .then(res => {
          this.cars = res.data.data;
        })
        .catch(error => { console.log(error) })
    },
    baiduMap() {
      this.map = new BMapGL.Map('allmap'); // 创建Map实例

      this.map.setMapStyleV2({
        styleId: 'e8b216bba3f191a5b779720ed124350e'
      });

      this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      this.initcar();
    },
    getadders() {
      // this.$Http.get("http://api.map.baidu.com/geocoder/v2/?location=" + this.markerArr[0].jzGnss.gnssY + "," + this.markerArr[0].jzGnss.gnssX + "&output=json&pois=1&ak=1GhQVYD3EEq77pBIZI4SYy0IZAeKLanU")
      // .then(res=>{
      //   console.log(res)
      // }

      // ).catch()
    },
    initcar() {
      var myIcon = new BMapGL.Icon(require("../../../../assets/trucks.svg"), new BMapGL.Size(52, 56));
      // 创建Marker标注，使用小车图标
      this.map.setMinZoom(16);
      for (let i = 0; i < this.markerArr.length; i++) {
        if (this.markerArr[i].jzGnss != '' && this.markerArr[i].jzGnss != null && this.markerArr[i].jzGnss != undefined) {
          // var myGeo = new BMapGL.Geocoder();//地址类解析
          var pt = new BMapGL.Point(this.markerArr[i].jzGnss.gnssY, this.markerArr[i].jzGnss.gnssX);
          this.map.centerAndZoom(pt, 13);// 初始化地图,设置中心点坐标和地图级别
          var opts = {
            position: new BMapGL.Point(this.markerArr[i].jzGnss.gnssY, this.markerArr[i].jzGnss.gnssX), // 指定文本标注所在的地理位置
            offset: new BMapGL.Size(30, -30) // 设置文本偏移量
          };
          // 创建文本标注对象
          var label = new BMapGL.Label('车辆：' + this.markerArr[i].driver.driverId + '; ' + '司机：' + this.markerArr[i].driver.userName, opts);
          // 自定义文本标注样式
          label.setStyle({
            display: 'none',
            color: '#000',
            borderRadius: '5px',
            borderColor: '#ccc',
            padding: '10px',
            fontSize: '16px',
            height: '30px',
            lineHeight: '10px',
            fontFamily: '微软雅黑'
          });
          var marker = new BMapGL.Marker(pt, {
            icon: myIcon
          });
          this.map.addOverlay(label); //开启信息窗口
          marker.addEventListener("mouseover", (e) => {
            // console.log(e.point,this.markerArr[i].jzGnss.gnssX,this.markerArr[i].jzGnss.gnssY);
            // myGeo.getLocation(e.point, function (res) {
            //   console.log(res);
            // });
            label.setStyle({
              display: 'block',

            });
          });
          marker.addEventListener("mouseout", function () {
            label.setStyle({
              display: 'none',

            });
          });
          // 将标注添加到地图
          this.map.addOverlay(marker);
        }

      }

    }
  },
};
/* eslint-enable */
</script>
<style scoped>
.baidumap {
  width: 100%;
  height: 5.7rem;
}
</style>
