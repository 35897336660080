<template>
	<div id="centreLeft1">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<span style="color:#5cd9e8">
					<icon name="chart-pie"></icon>
				</span>
				<div class="d-flex">
					<span class="fs-xl text mx-2" style="font-size:18px; color:#00fff6;"
						>用户状态</span
					>
					<dv-decoration-1
						style="width:1.25rem;height:.25rem; position:relative;top:-.0375rem;"
					/>
				</div>
			</div>
			<div
				class="d-flex jc-center"
				style="overflow:auto;box-sizing: border-box;display:block;"
			>
				<!-- <dv-scroll-board :config="ranking" style="width:10.9rem;height:4.43rem;" />:row-style="getRowClass" :header-row-style="getRowClass" :header-cell-style="getRowClass" -->
				<!-- <el-table :data="ranking" style="width: 100%" >
          <el-table-column type="index" label="序号" >
          </el-table-column>
          <el-table-column prop="yayunId" label="编码">
          </el-table-column>
          <el-table-column prop="cardId" label="名称">
          </el-table-column>
          <el-table-column prop="userName" label="负责人">
          </el-table-column>
          <el-table-column prop="driverPic" label="地址">
          </el-table-column>
          <el-table-column prop="driverId" label="街道办">
          </el-table-column>
          <el-table-column prop="phone" label="电话">
          </el-table-column>
          <el-table-column  label="状态">
            <template slot-scope="scope">
              <div>
                <span :style="stylefun(scope.row.status)">{{scope.row.status|fun}}</span>
              </div>
            </template>
</el-table-column>

</el-table> -->
				<div>
					<ol class="ols" style="margin-bottom:10px;">
						<li style="width:9.5%">编码</li>
						<li style="width:12%;">名称</li>
						<li style="width:10%;">负责人</li>

						<li style="width:14.5%;">电话</li>
						<li style="width:37%;">地址</li>
						<li style="width:16%;">状态</li>
					</ol>
					<div class="itemdata_list">
						<div class="lists">
							<ol class="ols ls" v-for="(item, index) in ranking" :key="index">
								<li style="width:9.5%;">{{ item.yayunId }}</li>
								<li style="width:12%;">{{ item.cardId }}</li>
								<li style="width:9%;">{{ item.userName }}</li>

								<li style="width:14.5%;">{{ item.phone }}</li>
								<li style="width:37%;">
									<!-- <lamp
										:datas="item.driverPic"
										:loop="false"
									></lamp> -->
									{{item.driverPic}}
								</li>
								<li style="width:16%;text-align:center;" :style="stylefun(item.status)">
									{{ item.statusDesc }}
								</li>
							</ol>
							<!-- <ol class="ols ls" v-for="(item, index) in ranking" :key="index">
                  <li style="width:10.5%;">{{ item.yayunId }}</li>
                  <li style="width:14%;">{{ item.cardId }}</li>
                  <li style="width:10.5%;">{{ item.userName }}</li>

                  <li style="width:14.5%;">{{ item.phone }}</li>
                  <li style="width:34%;">{{ item.driverPic }}</li>
                  <li style="width:12.5%;" :style="stylefun(item.status)">
                    {{ item.status | fun }}
                  </li>
                </ol> -->
							<!-- <van-swipe
              style="height: 300px;overflow:border:1px solid blue; hidden;line-height: 50px;text-align: center;"
              :autoplay="3000"
              indicator-color="white"
              vertical
            >
              <van-swipe-item  v-for="(item, index) in ranking" :key="index" style="height:40px;">
                <ol class="ols ls">
                  <li style="width:10.5%;">{{ item.yayunId }}</li>
                  <li style="width:14%;">{{ item.cardId }}</li>
                  <li style="width:10.5%;">{{ item.userName }}</li>

                  <li style="width:14.5%;">{{ item.phone }}</li>
                  <li style="width:34%;">{{ item.driverPic }}</li>
                  <li style="width:12.5%;" :style="stylefun(item.status)">
                    {{ item.status | fun }}
                  </li>
                </ol>
              </van-swipe-item>
            </van-swipe> -->
							<!-- <dv-scroll-board :config="ranking" style="width:100%;height:220px" /> -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
// import CentreLeft2Chart from "@/components/echart/centerLeft/centerLeft2Chart";
import lamp from '../components/horseracelamp/marquee';
export default {
	data() {
		return {
			time: '',
			padding: '',
			ranking: {
				// header: [
				//   "序号",
				//   "编码",
				//   "名称",
				//   "负责人",
				//   "地址",
				//   "街道办",
				//   "电话",
				//   "状态",
				// ],
				data: [],
				rowNum: 7, //表格行数
				headerHeight: 35,
				headerBGC: '#0f1325', //表头
				oddRowBGC: '#0f1325', //奇数行
				evenRowBGC: '#171c33', //偶数行
				// index: true,
				// columnWidth: [50],
				align: ['center'],
			},
		};
	},
	components: {
		lamp,
	},
	filters: {
		fun(e) {
			switch (e) {
				case -1:
					return '离线';

					break;
				case 0:
					return '正常';

					break;
				case 5:
					return '加注';

					break;
				case 1:
					return '一路浓度报警';

					break;
				case 2:
					return '二路浓度报警';

					break;
				case 3:
					return '三路浓度报警';

					break;
				case 4:
					return '三路浓度报警';

					break;
				case 32:
					return '液位高预警';

					break;
				case 33:
					return '液位高报警';

					break;
				case 34:
					return '液位低报警';

					break;
			}
		},
	},
	mounted() {
		this.time = setInterval(() => {
			this.getdev();
		}, 10000);
		this.getdev();
		this.marquee();
		setInterval(() => {
			this.marquee();
		}, 1000);
	},
	beforeDestroy() {
		clearInterval(this.time);
	},
	methods: {
		marquee() {
			let demo = document.getElementsByClassName('lists')[0];
			demo.scrollTop = demo.scrollTop + 5;
			if (this.padding != demo.scrollTop) {
				this.padding = demo.scrollTop;
			} else {
				demo.scrollTop = 0;
			}
		},
		stylefun(satus) {
			switch (satus) {
				case -1:
					return 'color:red;';

					break;
				case 0:
					return 'color:#fff;';

					break;
				case 5:
					return 'color:rgb(35,142,2)';

					break;
				case 1:
					return 'color:red;';

					break;
				case 2:
					return 'color:red;';

					break;
				case 3:
					return 'color:red;';

					break;
				case 4:
					return 'color:red;';

					break;
				case 32:
					return 'color:rgb(226,168,104);';

					break;
				case 33:
					return 'color:red;';

					break;
				case 34:
					return 'color:red;';

					break;
			}
		},
		// stylefun(satus) {
		//   switch (satus) {
		//     case -1:
		//       return '<span style="color:red;">离线</span>';

		//       break;
		//     case 0:
		//       return '<span style="color:#fff;">正常</span>';

		//       break;
		//     case 5:
		//       return '<span style="color:#238E02;">加注</span>';

		//       break;
		//     case 1:
		//       return '<span style="color:red;">一路浓度报警</span>';

		//       break;
		//     case 2:
		//       return '<span style="color:red;">二路浓度报警</span>';

		//       break;
		//     case 3:
		//       return '<span style="color:red;">三路浓度报警</span>';

		//       break;
		//     case 4:
		//       return '<span style="color:red;">四路浓度报警</span>';
		//     case 5:
		//       return '<span style="color:red;">五路浓度报警</span>';

		//       break;
		//     case 32:
		//       return '<span style="color:red;">液位高预警</span>';

		//       break;
		//     case 33:
		//       return '<span style="color:red;">液位高报警</span>';

		//       break;
		//     case 34:
		//       return '<span style="color:red;">液位低报警</span>';

		//       break;
		//   }
		// },
		// getRowClass({ row, column, rowIndex, columnIndex }) {
		//             return "background:#000;";
		//         },
		getdev() {
			this.$Http
				.get('wx/bigscreen/getNewDevices')
				.then((res) => {
					if (res.data.data != '') {
						// let ranking = {
						//   header: ["编码", "名称", "负责人", "电话", "地址", "状态"],
						//   data: [],
						//   rowNum: 7, //表格行数
						//   headerHeight: 35,
						//   headerBGC: "#0f1325", //表头
						//   oddRowBGC: "#0f1325", //奇数行
						//   evenRowBGC: "#171c33", //偶数行
						//   // index: true,
						//   // columnWidth: [50],
						//   align: ["center"],
						// };
						// for (let i = 0; i < res.data.data.length; i++) {
						//   let status = this.stylefun(res.data.data[i].status);
						//   ranking.data.push([
						//     res.data.data[i].yayunId,
						//     res.data.data[i].cardId,
						//     res.data.data[i].userName,
						//     res.data.data[i].phone,
						//     res.data.data[i].driverPic,
						//     status,
						//   ]);
						// }
						this.ranking = res.data.data;
					}
				})
				.catch((error) => {});
		},
	},
};
/* eslint-enable */
</script>
<style>
.rows .rows-item {
	height: 50px;
	line-height: 50px;
}
</style>
<style scoped>
/* @keyframes anis {
            100% {
                transform: translateY(-200px)
            }
        }
        
        .ls {
            animation: anis 10s linear infinite;
        }
        
        .ls:hover {
            animation-play-state: paused;
        } */
.ls {
	height: 40px;
	line-height: 40px;
}
.ols {
	display: flex;
	flex-wrap: nowrap;
	/* justify-content: space-around; */
	/* overflow: hidden; */
	height: 35px;
	padding-top: 2px;
	font-size: 12px;
}
.itemdata_list {
	width: 100%;
	height: 4rem;
	overflow: hidden;
	margin-top: -0.3rem;
}
.lists {
	width: 103%;
	height: 4rem;
	overflow: auto;
	padding-bottom: 30px;
}

li {
	text-align: center;
	font-size: 16px;
	color: aliceblue;
	width: 10%;
}
</style>
<style lang="scss">
#centreLeft1 {
	padding: 0.2rem;
	height: 5.125rem;
	min-width: 12.05rem;
	border-radius: 0.0625rem;
	overflow: hidden;
	.bg-color-black {
		height: 4.8125rem;
		border-radius: 0.125rem;
	}
	.text {
		color: #c3cbde;
	}
	.chart-box {
		margin-top: 0.2rem;
		width: 2.125rem;
		height: 2.125rem;
		.active-ring-name {
			padding-top: 0.125rem;
		}
	}
}
</style>
