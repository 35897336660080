<template>
	<div id="centreRight1">
		<div class="bg-color-black">
			<div class="d-flex pt-2 pl-2">
				<span style="color:#5cd9e8">
					<icon name="chart-line"></icon>
				</span>
				<div class="d-flex">
					<span class="fs-xl text mx-2" style="font-size:18px; color:#00fff6;"
						>实时报警</span
					>
				</div>
			</div>
			<div
				class="d-flex jc-center body-box"
				style="overflow:hidden;height:4.235rem;"
				id="warningmsgdata"
			>
				<div
					style="width: 100%; height: 130px;"
					v-for="(item, index) in config"
					:key="index"
					v-show="isshow"
				>
					<dv-border-box-8 style="width: 98%; height: 110px;">
						<div class="box-8-item">
							<div class="box-8-img">
								<img
									v-show="item.descr == '液位高预警'"
									src="../assets/leveheightwarning.png"
									alt=""
								/>
								<img
									v-show="item.descr == '液位高报警'"
									src="../assets/leveheighterror.png"
									alt=""
								/>
								<img
									v-show="item.descr == '第一路浓度报警'"
									src="../assets/equipmentfailure.png"
									alt=""
								/>
								<img
									v-show="item.descr == '第二路浓度报警'"
									src="../assets/equipmentfailure.png"
									alt=""
								/>
								<img
									v-show="item.descr == '第三路浓度报警'"
									src="../assets/equipmentfailure.png"
									alt=""
								/>
								<img
									v-show="item.descr == '加注中'"
									src="../assets/fill.png"
									alt=""
								/>
								<img
									v-show="item.descr == '液位低报警'"
									src="../assets/levelowerror.png"
									alt=""
								/>
							</div>
							<div class="box-8-msg">
								<p style="color:#ffffff;font-size:18px;">{{ item.cardid }}</p>
								<p style="color:red;">{{ item.descr }}</p>
								<p style=" width:2rem;margin-top:0.05rem">
									<lamp
										:datas="item.driverpic"
										:loop="true"
									></lamp>
								</p>
							</div>
						</div>
					</dv-border-box-8>
				</div>
				<dv-border-box-8
					style="height:4rem;padding-bottom:10px;"
					v-show="!isshow"
				>
					<div
						style="width:100;margin:0 auto;height:100%;line-height:100px;text-align:center;color:green;font-size:18px;"
					>
						<img
							style="margin-top:10px;"
							src="../assets/Thedatais normal.png"
							alt=""
						/><br />
						<span style="margin-top:-70px;display:block;">数据正常</span>
					</div>
				</dv-border-box-8>
			</div>
		</div>
	</div>
</template>

<script>
import lamp from '../components/horseracelamp/marquee';
/* eslint-disable */

export default {
	data() {
		return {
			timerCount: '',
			video: '',
			isshow: true,
			config: [],
		};
	},
	components: {},
	beforeDestroy() {
		clearInterval(this.timerCount);
	},
	mounted() {
		this.timerCount = setInterval(() => {
			this.banner();
			this.getwarning();
		}, 10000);
		this.banner();
		this.getwarning();
	},
	deactivated() {
		window.clearInterval(this.timerCount);
	},
	methods: {
		banner() {
			let documod = window.document.getElementById('warningmsgdata'),
				dom = documod.children[0];
			documod.removeChild(dom);
			documod.appendChild(dom);
		},
		//获取实时报警
		getwarning() {
			this.$Http
				.get('wx/bigscreen/getWaningList')
				.then((res) => {
					this.video = document.getElementById('video');
					let flag = false;
					if (res.data.errno == 0) {
						this.config = res.data.data;
						this.isshow = true;
						// res.data.data;
						for (let i = 0; i < res.data.data.length; i++) {
							if (
								res.data.data[i].type == 5 ||
								res.data.data[i].type == 4 ||
								res.data.data[i].type == 6
							) {
								flag = true;
								break;
							}
						}
					} else {
						this.isshow = false;
					}

					if (flag) {
						this.video.play();
					} else {
						this.video.pause();
					}
				})
				.catch((error) => {});
		},
	},
	components: {
		lamp,
	},
};
/* eslint-enable */
</script>

<style lang="scss">
#centreRight1 {
	padding: 0.2rem;
	height: 5.125rem;
	min-width: 3.75rem;
	border-radius: 0.0625rem;
	.bg-color-black {
		height: 4.8125rem;
		border-radius: 0.125rem;
	}
	.text {
		color: #c3cbde;
	}
	.body-box {
		border-radius: 0.125rem;
		overflow: hidden;
		.box-8-item {
			width: 100%;
			height: 1rem;
			// border: 1px solid red;
			display: flex;
			.box-8-img {
				width: 30%;
				img {
					display: block;
					margin: 50% auto;
					width: 45%;
					height: 30%;
				}
			}
			.box-8-msg {
				width: 70%;
				p {
					width: 100%;
					height: 30px;
					line-height: 30px;
					margin-top: 3.5%;
					font-size: 16px;
					color: #00fff6;
				}
			}
		}
	}
}
</style>
