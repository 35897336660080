<template>
  <div>
    <baidumap :cars="cars" />
  </div>
</template>

<script>
/* eslint-disable */
import baidumap from './baidumap.vue'
import qs from 'qs'
window.qs = qs; 
export default {
  data () {
    return {
        time:'',
      cars:'',

    };
  },
  components: {
    baidumap,
  },
   beforeDestroy(){
    clearInterval(this.time)
  },
  created() {
    this.getcar();
  },
  mounted () {
     this.time = setInterval(() => {
      this.getcar();
    }, 10000);
    
  },
  methods: {
     getcar() {
      let request = {
        "Action": "GetVehicleGpsInfo", 
        "UserId": "sxwyny",
        "Pwd": "D5D9E6BF8E8AEE5B91EA69D1D2498B5B",
        "Vehicles": [{
          "PlateNum": "测A000022",
          "ColorCode": "1"
        }
        ],
        "NeedAddress": "1"
      } 
      this.cars = [{"PlateNum":"测A000022","ColorCode":"1","GpsTime":"2020/12/12 7:53:57","Mileage":"0.00","Speed":"0.0","Longitude":"112.552104","Latitude":"37.788568","Address":"山西省太原市小店区晋阳街 格云楼西北50米","Direction":"2","Altitude":"0","IsGpsValid":"1","AlarmInfo":"终端主电源掉电;","StateInfo":"ACC关;定位;运营;电路断开;"}]
      // this.$Http.post('/topgps/services/TopDataService.ashx',{request:request}, {headers: {'content-type': 'application/x-www-form-urlencoded; charset=UTF-8'}})
      //   .then(res => {
      //     console.log(res)
      //   })
      //   .catch(error => {console.log(error)})
    }
  }
};
/* eslint-enable */
</script>

<style lang="scss" scoped>
</style>