<template>
  <div id="centreRight2">
    <div class="bg-color-black">
      <div class="d-flex pt-2 pl-2">
        <span style="color:#5cd9e8">
          <icon name="align-left"></icon>
        </span>
        <span class="fs-xl text mx-2" style='font-size:18px; color:#00fff6;'>报警统计</span>
      </div>
      <div class="d-flex jc-center body-box" style=" margin-top: 0;">
        <dv-capsule-chart :config="config" style="width: 100%;height:1.8rem;margin-bottom: 0.65rem;" />
        <!-- <centreRight2Chart1 /> -->
        <div style="width:95%;margin:0 auto;height:2.7rem;">
          <dv-border-box-10 style="height:1rem;text-indent:5px;color:red;font-size:14px;">
            <div style="width:100%;height:100%;padding-top:.1rem;overflow: hidden;">
              <p style="color:red;font-size:18px;font-weight:bold;margin-top:.1rem;">设备离线</p>
              <van-swipe style="height: 50px;overflow: hidden;line-height: 50px;text-align: center;" :autoplay="3000" indicator-color="white" vertical>
                <van-swipe-item v-for="(item,index) in lxdev" :key="index">
                   设备编号：{{item}}
                </van-swipe-item>
 
              </van-swipe>
              
              
            </div>
          </dv-border-box-10>
          <dv-border-box-10 style="height:1rem;text-indent:5px;color:red;font-size:14px;margin-top:.1rem;">
            <div style="width:100%;height:100%;padding-top:.1rem;">
                <p style="color:red;font-size:18px;font-weight:bold;margin-top:.1rem;">异常加注</p>
                <van-swipe style="height: 50px;overflow: hidden;line-height: 50px;text-align: center;" :autoplay="3000" indicator-color="white" vertical>
                    <van-swipe-item v-for="(item,index) in ycdev" :key="index">
                       设备编号：{{item}}
                    </van-swipe-item>
     
                  </van-swipe>
              </div>
          </dv-border-box-10>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    /* eslint-disable */
    // import centreRight2Chart1 from "@/components/echart/centerRight/centerRightChart";

    export default {
        data() {
            return {
                lxdev: '',
                ycdev: '',
                warningmessage: '',
                config: {
                    data: []
                }
            };
        },
        // components: { centreRight2Chart1 },
        mounted() {
            this.getwarning();
            this.time = setInterval(() => {
                this.getwarning();
            }, 10000);

        },
        watch: {
            config(newval, oldva) {

            },
            deep: true
        },
        beforeDestroy() {
            clearInterval(this.time)
        },
        methods: {
            getwarning() {
                this.$Http.get('wx/bigscreen/getWaringTotal').then(res => {
                    if (res.data.data != '') {
                        this.lxdev = res.data.data.leveDevice;
                        this.ycdev = res.data.data.ycDevice;
                        let config = {
                            data: []
                        }
                        config.data = [{
                            name: res.data.data.swflag1descr,
                            value: res.data.data.swflag1Sum
                        }, {
                            name: res.data.data.swflag2descr,
                            value: res.data.data.swflag2Sum
                        }, {
                            name: res.data.data.swflag3descr,
                            value: res.data.data.swflag3Sum
                        }, {
                            name: res.data.data.swflag4descr,
                            value: res.data.data.swflag4Sum
                        }, {
                            name: res.data.data.swflag5descr,
                            value: res.data.data.swflag5Sum
                        }, {
                            name: res.data.data.swSumdescr,
                            value: res.data.data.swSum
                        }, {
                            name: res.data.data.yeweidescr,
                            value: res.data.data.yeweiSum
                        }]
                        this.config = config;
                    }

                }).catch(error => {})
            }
        }
    };
    /* eslint-enable */
</script>

<style lang="scss">
    #centreRight2 {
        padding: 0.0625rem;
        height: 5rem;
        min-width: 3.75rem;
        border-radius: 0.0625rem;
        .bg-color-black {
            padding: 0.0625rem;
            height: 5.0625rem;
            border-radius: 0.125rem;
        }
        .text {
            color: #c3cbde;
        }
        .body-box {
            border-radius: 0.125rem;
            overflow: hidden;
        }
    }
</style>