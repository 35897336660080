<template>
	<div id="lamp">
		<div  class="sollc">
				<p>
			<span id="sollc_span">{{ datas }}</span>
		</p>
		</div>
	
	</div>
</template>
<script>
export default {
	/* eslint-disable no-dupe-keys */
	props: ['datas', 'loop'],
	data: function() {
		return {
			padding: 0,
			num: 0,
		};
	},
	watch: {
		data(newval, oldval) {
			console.log(newval, oldval);
		},
		message(newval) {
			console.log(newval);
		},
		number(newval) {
			console.log(newval);
		},
	},
	mounted() {
		if (this.loop) {
			setInterval(() => {
				this.marquee();
			}, 1000);
		}
	},
	methods: {
		/* eslint-disable no-empty */
		marquee() {
			let demo = document.getElementsByClassName('sollc');
			console.log(demo);
			demo.forEach((item) => {
				item.scrollLeft = item.scrollLeft + 1;
				console.log(this.padding != item.scrollLeft);
				if (this.padding != item.scrollLeft) {
					this.padding = item.scrollLeft;
				} else {
					item.scrollLeft = 0;
				}
			});
		},
	},
};
</script>
<style scoped>
#lamp {
	overflow: hidden;
	height: 0.2rem;
}
.sollc {
	width: 2.25rem;
	line-height: 1px;
	text-align: left;
	margin-top: -10px;
	
	overflow: auto;
}
#sollc_span {
	display: inline-block;
		height: 3rem;
	white-space: nowrap;
	padding-right: 1rem;
	/* padding-right: 5rem; */
}
</style>
